<template>
  <main>
    <Loading v-if="isLoading"></Loading>
    <div v-else>
      <h1 class="text-2xl mt-5 font-bold text-blue-800">
        Wallet Management Portal
      </h1>
      <div class="flex my-3">
        <div class="">
          <button
            class="bg-gray-800 py-2 px-3 rounded-md text-white text-xs"
            @click="
              $router.push({
                name: 'WalletManagementPortalSelectBusiness',
              })
            "
          >
            &laquo; Back
          </button>
        </div>
      </div>
      <div class="flex flex-wrap xl:-mx-4 pb-5">
        <div class="w-full xl:my-4 xl:px-4">
          <p class="text-green-600" v-if="successMssg">
            Admin was created successful
          </p>
        </div>

        <div class="w-2/3 xl:my-4 xl:px-4">
          <div
            class="w-full p-5 pb-20 border-2 border-gray-100 bg-white rounded-lg"
          >
            <div>
              <form @submit.prevent="handleWalletSubmit">
                <div class="flex flex-wrap xl:-mx-2">
                  <div class="w-full xl:my-2 xl:px-2 xl:w-1/2">
                    <!-- Column Content -->
                    <p class="text-xs font-bold mb-3">Business ID</p>
                    <p class="text-sm font-semibold">
                      <input
                        type="text"
                        class="inline-block p-3 ring-1 w-full outline-none ring-gray-300 rounded-sm text-sm"
                        name=""
                        placeholder="Enter Business ID"
                        disabled
                        v-model="businessID"
                        required
                      />
                    </p>
                  </div>

                  <div class="w-full xl:my-2 xl:px-2 xl:w-1/2">
                    <!-- Column Content -->
                    <p class="text-xs font-bold mb-3">Business Email</p>
                    <p class="text-sm font-semibold">
                      <input
                        type="text"
                        class="inline-block p-3 ring-1 w-full outline-none ring-gray-300 rounded-sm text-sm"
                        name=""
                        placeholder="Enter Last Name"
                        id=""
                        v-model="businessEmail"
                        required
                        disabled
                      />
                    </p>
                  </div>
                </div>
                <div class="flex flex-wrap xl:-mx-2">
                  <div class="w-full xl:my-2 xl:px-2 xl:w-1/2">
                    <!-- Column Content -->
                    <p class="text-xs font-bold mb-3">Amount</p>
                    <p class="text-sm font-semibold">
                      <input
                        type="number"
                        class="inline-block p-3 ring-1 w-full outline-none ring-gray-300 rounded-sm text-sm"
                        placeholder="Enter Amount"
                        v-model="amount"
                        min="1"
                        required
                      />
                    </p>
                  </div>

                  <div class="w-full xl:my-2 xl:px-2 xl:w-1/2">
                    <!-- Column Content -->
                    <p class="text-xs font-bold mb-3">Narration</p>
                    <p class="text-sm font-semibold">
                      <input
                        type="text"
                        class="inline-block p-3 ring-1 w-full outline-none ring-gray-300 rounded-sm text-sm"
                        name=""
                        placeholder="Narration"
                        id=""
                        v-model="narration"
                        required
                      />
                    </p>
                  </div>
                </div>

                <div class="my-5 w-full">
                  <button
                    class="py-3 px-6 bg-blue-800 float-right rounded-md font-semibold text-white text-sm"
                    type="submit"
                  >
                    Submit
                  </button>
                  <button
                    class="py-3 px-6 bg-transparent float-right rounded-md font-semibold text-red-600 text-sm"
                    type="button"
                    @click="resetForm"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="alertDiv"
        class="modal fixed bg-opacity-50 bg-gray-900 flex justify-center items-center w-screen h-screen top-0 left-0 z-40"
      >
        <div
          class="w-1/4 rounded-lg bg-white p-5 grid grid-cols-1 gap-4 text-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="currentColor"
            class="bi bi-check2-circle text-green-500 mx-auto"
            viewBox="0 0 16 16"
          >
            <path
              d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"
            />
            <path
              d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"
            />
          </svg>
          <p class="text-sm">Thank you, your request has been sent.</p>
          <button
            @click="$router.push({ name: 'WalletManagementPortal' })"
            class="float-right uppercase shadow mx-auto hover:bg-red-500 bg-red-600 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-2 rounded"
            style="width: inherit"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import Loading from './general-components/Loading.vue'
export default {
  data() {
    return {
      moment,
      isLoading: false,
      businessID: '',
      businessEmail: '',
      businessPhone: '',
      businessName: '',
      errorMssg: false,
      successMssg: false,
      promptModal: false,
      amount: '',
      narration: '',
      alertDiv: false,
    }
  },
  components: {
    Loading,
  },
  computed: {
    ...mapGetters(['GET_LOCATIONS']),
    filterLocations() {
      return this.GET_LOCATIONS.data
    },
  },
  filters: {
    truncate: function(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix
      } else {
        return text
      }
    },
  },
  async created() {
    this.isLoading = true
    this.businessID = this.$route.params.id
    this.businessEmail = this.$route.params.email
    this.businessName = this.$route.params.businessname
    try {
      let res = await this.$store.dispatch(
        'WALLET_CREDITING_FETCH_SELECT_BUSINESS',
        { businessEmail: this.businessEmail }
      )
      this.isLoading = false
      if (res.status) {
        // console.log(res.data.business_name);
        this.businessName = res.data.business_name
      }
      // res.
    } catch {}

    // console.log(this.$route.query.debit);
  },
  methods: {
    ...mapActions([
      'WALLET_CREDITING_FETCH_SELECT_BUSINESS',
      'CREATE_WALLET_DEBIT_REQUEST',
      'CREATE_WALLET_CREDITING_REQUEST',
    ]),
    resetForm() {
      this.amount = ''
      this.narration = ''
    },
    async handleWalletSubmit() {
      const response = confirm('Are you sure you want to continue?')
      if (response === true) {
        if (this.$route.query.debit === 'true') {
          let payload = {
            business_id: this.businessID,
            business_email: this.businessEmail,
            amount_debited: parseInt(this.amount, 10),
            narration: this.narration,
            business_name: this.businessName,
            business_phone: this.$route.params.phoneNumber,
          }
          this.isLoading = true
          try {
            let res = await this.$store.dispatch(
              'CREATE_WALLET_DEBIT_REQUEST',
              payload
            )
            this.isLoading = false
            if (res.status) {
              this.businessID = ''
              this.businessEmail = ''
              this.amount = ''
              this.narration = ''
              this.alertDiv = true
            } else {
              this.successMssg = false
              this.errorMssg = true
              alert('Sorry, your crediting request was unsuccessful.')
            }
          } catch (error) {}
        } else {
          let payload = {
            business_id: this.businessID,
            business_email: this.businessEmail,
            amount_credited: parseInt(this.amount, 10),
            narration: this.narration,
            business_name: this.businessName,
            business_phone: this.$route.params.phoneNumber,
          }
          this.isLoading = true
          try {
            let res = await this.$store.dispatch(
              'CREATE_WALLET_CREDITING_REQUEST',
              payload
            )
            this.isLoading = false
            if (res.status) {
              this.businessID = ''
              this.businessEmail = ''
              this.amount = ''
              this.narration = ''
              this.alertDiv = true
            } else {
              this.successMssg = false
              this.errorMssg = true
              alert('Sorry, your crediting request was unsuccessful.')
            }
          } catch (error) {}
        }
      }
    },
  },
}
</script>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(238, 238, 238);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
